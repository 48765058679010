import { mergeClasses } from '@expo/styleguide';
import { HelpCircleIcon } from '@expo/styleguide-icons/outline/HelpCircleIcon';
import * as Popover from '@radix-ui/react-popover';
import { PropsWithChildren, ReactNode } from 'react';

import { Button } from '~/ui/components/Button';

import { BoxContentContainer } from '../Box/BoxContentContainer';
import { BoxWrapper } from '../Box/BoxWrapper';

type Props = PropsWithChildren<{
  contentClassName?: string;
  trigger?: ReactNode;
}>;

export function PopoverHelp({ children, contentClassName, trigger }: Props) {
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        {trigger ?? (
          <Button theme="quaternary" title="Additional information" className="h-7 px-1">
            <HelpCircleIcon className="icon-sm text-icon-secondary" />
          </Button>
        )}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className={mergeClasses(
            'z-50 mr-4 mt-1.5 w-[calc(100vw-28px)] max-w-[400px] animate-largeSlideDownAndFadeIn',
            contentClassName
          )}>
          <BoxWrapper className="shadow-md">
            <BoxContentContainer size="header">{children}</BoxContentContainer>
          </BoxWrapper>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
