import { mergeClasses } from '@expo/styleguide';
import { PropsWithChildren, ReactElement } from 'react';

type SidebarLayoutProps = PropsWithChildren<{
  sidebar: ReactElement;
  className?: string;
}>;

function SidebarLayout({ sidebar, children, className }: SidebarLayoutProps) {
  return (
    <div className="grid min-h-screen grid-cols-[260px_1fr] max-md-gutters:grid-cols-[1fr]">
      <div className="border-r border-secondary max-md-gutters:hidden">{sidebar}</div>
      <div className={mergeClasses('bg-screen', className)}>{children}</div>
    </div>
  );
}

export default SidebarLayout;
