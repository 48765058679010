import { mergeClasses } from '@expo/styleguide';
import { QuestionMarkIcon } from '@expo/styleguide-icons/custom/QuestionMarkIcon';
import { ComponentType, HTMLAttributes, ReactNode } from 'react';

import { Button } from '~/ui/components/Button';
import { PopoverHelp } from '~/ui/components/PopoverHelp';
import { CALLOUT, H2, P } from '~/ui/components/text';

type Props = {
  title: ReactNode;
  Icon?: ComponentType<HTMLAttributes<SVGSVGElement>>;
  helpText?: ReactNode;
  description?: ReactNode;
  rightSlot?: ReactNode;
  bottomSlot?: ReactNode;
  className?: string;
};

export function PageHeader({
  title,
  Icon,
  description,
  rightSlot,
  bottomSlot,
  helpText,
  className,
}: Props) {
  return (
    <div
      className={mergeClasses(
        'mx-auto grid w-full max-w-screen-xl-gutters grid-cols-1 items-center gap-4 px-6 pb-2 pt-8',
        className
      )}>
      <div
        className={mergeClasses(
          'flex items-center justify-between gap-3',
          'max-md-gutters:flex-col max-md-gutters:items-start'
        )}>
        <div className="grid grid-cols-1 gap-2">
          <div className="flex items-center gap-3">
            {Icon && <Icon className="icon-xl shrink-0" />}
            <H2 tag="h1" variant="oneLine">
              {title}
            </H2>
            {helpText && (
              <PopoverHelp
                contentClassName="max-w-[300px]"
                trigger={
                  <Button
                    theme="secondary"
                    title="Additional information"
                    className="size-6 justify-center rounded-full px-0">
                    <QuestionMarkIcon className="icon-xs text-icon-secondary" />
                  </Button>
                }>
                {typeof helpText === 'string' ? <P>{helpText}</P> : helpText}
              </PopoverHelp>
            )}
          </div>
          {description && typeof description === 'string' && (
            <CALLOUT theme="secondary">{description}</CALLOUT>
          )}
          {description && typeof description !== 'string' ? description : null}
        </div>
        {rightSlot && <div className="justify-self-end">{rightSlot}</div>}
      </div>
      {bottomSlot}
    </div>
  );
}
